import React from 'react';
import { MenuItem as MuiMenuItem } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const MenuItem = ({ label, to }) => {
  return (
    <MuiMenuItem
      component={RouterLink}
      to={to}
      sx={{
        textDecoration: 'none',
        color: '#000000',
      }}
    >
      {label}
    </MuiMenuItem>
  );
};

export default MenuItem;
