import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Link } from '@mui/material';
import { supabase } from '../supabase/supabaseClient';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/LoginPageComponents/LoginForm';
import RegisterForm from '../components/LoginPageComponents/RegisterForm';
import logo from '../assets/logos/LoveMyTask_logo.png'; // Importáljuk a logo képet

const LoginPage = () => {
  const [isRegister, setIsRegister] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Kép előtöltése useEffect segítségével
  useEffect(() => {
    const preloadImage = new Image();
    preloadImage.src = logo;
  }, []); // Ez csak egyszer fut le, amikor a komponens betöltődik

  const handleLogin = async (email, password) => {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      setError(error.message);
    } else {
      setError(null);
      navigate('/home');
    }
  };

  const handleRegister = async (email, password) => {
    const { error } = await supabase.auth.signUp({
      email,
      password,
    });

    if (error) {
      setError(error.message);
    } else {
      setError(null);
      navigate('/home');
    }
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        {/* Logo elhelyezése */}
        <Box component="img" src={logo} alt="LoveMyTask Logo" sx={{ height: '50px', mb: 2 }} />

        {!isRegister ? (
          <>
            <Typography component="h1" variant="h5" sx={{ fontWeight: '600' }}>
              Login
            </Typography>
            <LoginForm handleLogin={handleLogin} error={error} />
            <Typography variant="body2" sx={{ mt: 2 }}>
              Don't have an account?{' '}
              <Link
                component="button"
                variant="body2"
                onClick={() => setIsRegister(true)} // Regisztrációs form megjelenítése
              >
                Let's create an account
              </Link>
            </Typography>
          </>
        ) : (
          <>
            <Typography component="h1" variant="h5" sx={{ fontWeight: '600' }}>
              Register
            </Typography>
            <RegisterForm handleRegister={handleRegister} error={error} />
            <Typography variant="body2" sx={{ mt: 2 }}>
              Already have an account?{' '}
              <Link
                component="button"
                variant="body2"
                onClick={() => setIsRegister(false)} // Vissza a bejelentkezési formhoz
              >
                Login here
              </Link>
            </Typography>
          </>
        )}
      </Box>
    </Container>
  );
};

export default LoginPage;
