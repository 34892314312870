import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabase/supabaseClient';
import MenuItem from './MenuItem'; // Importáljuk a MenuItem komponenst
import logo from '../../assets/logos/LoveMyTask_logo.png'; // Importáljuk a logo képet
import MobileNavBar from './MobileNavBar'; // Importáljuk a mobil menüt

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      if (position > window.innerHeight) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/'); // Kijelentkezés után navigáció a bejelentkező oldalra
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: isScrolled ? 'white' : 'rgba(255, 255, 255)', // Fehér háttér
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Halvány box shadow alul
        transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Bal oldalra igazított logo */}
        <Box component="img" src={logo} alt="LoveMyTask Logo" sx={{ height: '30px' }} />

        {/* Mobil menü */}
        <MobileNavBar />

        {/* Normál menü nagy kijelzőkhöz */}
        <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', gap: 2 }}>
          <MenuItem label="Home" to="/home" />
          <MenuItem label="Projects" to="/projects" />
          <MenuItem label="Settings" to="/Settings" />
          <MenuItem label="Logout" to="/" onClick={handleLogout} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
