import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabase/supabaseClient';
import { Container, Typography, Box } from '@mui/material';

const Home = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getSession = async () => {
      const { data } = await supabase.auth.getSession();
      if (!data.session) {
        navigate('/'); // Ha nincs session, irányítás a bejelentkező oldalra
      } else {
        setUser(data.session?.user ?? null);
      }
    };

    getSession();
  }, [navigate]);

  return (
    <Container
      maxWidth="md"
      sx={{
        height: 'calc(100vh - 64px)', // Figyelembe vesszük a navbar magasságát
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '64px' // Navigációs sáv miatt
      }}
    >
      <Box>
        <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', mb: 2 }}>
          Home Page
        </Typography>
        <Typography variant="h6" sx={{ mb: 4 }}>
          Welcome, {user ? user.email : 'Guest'}
        </Typography>
      </Box>
    </Container>
  );
};

export default Home;
