import React, { useState } from 'react';
import { TextField, Button, Box, Alert } from '@mui/material';

const RegisterForm = ({ handleRegister, error }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    handleRegister(email, password);
  };

  return (
    <Box component="form" onSubmit={onSubmit} sx={{ mt: 3, width: '100%' }}>
      {error && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{error}</Alert>}
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        autoFocus
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="new-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
      >
        Register
      </Button>
    </Box>
  );
};

export default RegisterForm;
