import { createTheme } from '@mui/material/styles';

// Egyedi téma létrehozása
const theme = createTheme({
  shape: {
    borderRadius: 6, // Globális border-radius érték
  },
  palette: {
    primary: {
      main: '#8b52ff', // Alapértelmezett elsődleges szín
      light: '#8b52ff', // Opcionális világosabb árnyalat
      dark: '#59811e',  // Opcionális sötétebb árnyalat
    },
    secondary: {
      main: '#222629', // Alapértelmezett másodlagos szín
      light: '#4a4f54', // Opcionális világosabb árnyalat
      dark: '#111213',  // Opcionális sötétebb árnyalat
    },
    custom: { // Egyedi színek a palette részeként
      lightGreen: '#8b52ff',
      darkGray: '#222629',
      white: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Urbanist, sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '3rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '2.5rem',
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#000000', // Örökölje a színt
          textTransform: 'none',
          fontWeight: '600',
          fontSize: '1rem',
          padding: '8px 16px', // Menü elem padding
          '&:hover': {
            backgroundColor: 'transparent',
            color: '#8b52ff', // Hover szín
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6, // Globális border-radius a gombokra
          padding: '10px 20px', // Alapértelmezett padding
          fontWeight: 600, // Alapértelmezett betűstílus
          backgroundColor: '#8b52ff', // Használjuk a palette.custom.lightGreen színt
          '&:hover': {
            backgroundColor: '#4a4f54', // Hover állapot fehér színnel
            color: '#ffffff'
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12, // Speciális border-radius a kártyákon
          backgroundColor: '#86C232', // Kártyákon is a palette.custom.lightGreen színt használjuk
        },
      },
    },
    MuiContainer: {  // Container komponens alapértelmezett beállításai
      styleOverrides: {
        root: {
          maxWidth: '90%', // Globális maxWidth minden Container elemnél
        },
      },
      defaultProps: {
        maxWidth: false, // Kikapcsoljuk az alapértelmezett maxWidth értéket
      },
    },
  },
});

export default theme;
