import React, { useState, useEffect } from 'react';
import { Modal, TextField, Button, Box, MenuItem } from '@mui/material';
import { supabase } from '../../supabase/supabaseClient';

// Prioritások listája
const priorities = ['Low', 'Medium', 'High'];

const ProjectForm = ({ open, handleClose, onProjectAdded }) => {
  const [name, setName] = useState('');
  const [deadline, setDeadline] = useState(''); // Dátum mező alapértelmezés szerint üres
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState('Medium');

  useEffect(() => {
    // Alapértelmezett dátum beállítása a mai napra
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0]; // ISO formátumban ma dátum
    setDeadline(formattedDate); // Ha nem nyúlnak hozzá, ez lesz a dátum érték
  }, []);

  const resetForm = () => {
    setName('');
    setDeadline('');
    setDescription('');
    setPriority('Medium');
  };

  const handleAddProject = async () => {
    const { data: { session } } = await supabase.auth.getSession();

    if (!session) {
      alert('User is not authenticated');
      return;
    }

    const userId = session.user.id;

    const selectedDate = new Date(deadline);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Alert megjelenítése, ha múltbéli vagy mai dátumot ad meg
    if (selectedDate <= today) {
      const confirmAlert = window.confirm("Biztos, hogy mai vagy múltbéli időpontot adsz meg?");
      if (!confirmAlert) {
        return;
      }
    }

    const { data, error } = await supabase
      .from('projects')
      .insert([{ name, deadline, description, priority, user_id: userId }])
      .select();

    if (error) {
      alert('Error adding project: ' + error.message);
    } else {
      if (data && data.length > 0) {
        onProjectAdded(data[0]); // Új projekt azonnal hozzáadásra kerül
        resetForm();
        handleClose(); // Modal bezárása
      }
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          '@media (max-width:600px)': {
            width: '90%',
            padding: '16px',
          },
        }}
      >
        <h2>Add New Project</h2>
        <TextField
          label="Project Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Description"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Deadline"
          type="date"
          fullWidth
          value={deadline} // Ha a mező üres, az alapértelmezett dátumot fogja használni
          onChange={(e) => setDeadline(e.target.value)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Priority"
          select
          fullWidth
          value={priority}
          onChange={(e) => setPriority(e.target.value)}
          margin="normal"
        >
          {priorities.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <Button variant="contained" color="primary" onClick={handleAddProject} sx={{ marginTop: '1rem' }}>
          Add Project
        </Button>
      </Box>
    </Modal>
  );
};

export default ProjectForm;
