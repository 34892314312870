import React from 'react';
import { useParams } from 'react-router-dom';
import ProjectDetails from '../../components/ProjectsPageComponents/ProjectDetails'; // Helyes útvonal

const ProjectDetailsPage = () => {
  const { projectId } = useParams();

  return <ProjectDetails projectId={projectId} />;
};

export default ProjectDetailsPage;
