import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, Link, Button, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { supabase } from '../../supabase/supabaseClient';
import TaskForm from './TaskForm';
import ProjectTasks from './ProjectTasks';
import logo from '../../assets/logos/LoveMyTask_logo.png';

const priorities = ['All', 'Low', 'Medium', 'High'];

const ProjectDetails = ({ projectId, onProjectDeleted }) => {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [newTask, setNewTask] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [priorityFilter, setPriorityFilter] = useState('All');
  const [sortOrder, setSortOrder] = useState('desc');
  const [columns, setColumns] = useState(2);

  useEffect(() => {
    if (projectId) {
      const fetchProject = async () => {
        const { data, error } = await supabase
          .from('projects')
          .select('*')
          .eq('id', projectId);

        if (error) {
          console.error('Error fetching project:', error);
        } else {
          setProject(data[0]);
          setLoading(false);
        }
      };

      fetchProject();
    } else {
      setLoading(false);
    }
  }, [projectId]);

  const handleTaskAdded = (task) => {
    setNewTask(task);
    setOpenModal(false);
  };

  const handleDeleteProject = async () => {
    const confirmed = window.confirm(`Are you sure to delete project "${project.name}"?`);
    if (confirmed) {
      const { error } = await supabase
        .from('projects')
        .delete()
        .eq('id', projectId);

      if (error) {
        console.error('Error deleting project:', error);
      } else {
        onProjectDeleted();
      }
    }
  };

  if (!projectId) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', textAlign: 'center' }}>
        <img src={logo} alt="Logo" style={{ width: '200px', marginBottom: '20px' }} />
        <Typography variant="h5">Pick your next project</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: '#f7f7f7',
        padding: '16px',
        borderRadius: '8px',
        height: '100%',
        maxHeight: 'calc(100vh - 64px)',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
      }}
    >
      {project ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">
              <Typography component="span" variant="h6" sx={{ color: '#8B52FF' }}>{project.name}</Typography> Details
            </Typography>
            <Link
              component="button"
              variant="body2"
              onClick={handleDeleteProject}
              sx={{ color: 'text.secondary', textDecoration: 'none', cursor: 'pointer', '&:hover': { color: 'error.main' } }}
            >
              Delete Project
            </Link>
          </Box>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <Typography variant="body1">Project name: <strong>{project.name}</strong></Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">Deadline: <strong>{project.deadline}</strong></Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">Priority: <strong>{project.priority}</strong></Typography>
            </Grid>
          </Grid>

          <Typography variant="body1" sx={{ marginTop: '16px' }}>
            Description: <strong>{project.description}</strong>
          </Typography>

          <Box sx={{ height: '2px', backgroundColor: 'purple', marginY: '16px' }} />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
            <Typography variant="h6">Task List</Typography>
            <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
              Add Task
            </Button>
          </Box>

          <Grid container spacing={2} alignItems="center" mb={2}>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel>Priority</InputLabel>
                <Select
                  value={priorityFilter}
                  onChange={(e) => setPriorityFilter(e.target.value)}
                  label="Priority"
                  size="small"
                  sx={{ backgroundColor: '#ffffff' }}
                >
                  {priorities.map((priority) => (
                    <MenuItem key={priority} value={priority}>
                      {priority}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel>Sort by Deadline</InputLabel>
                <Select
                  value={sortOrder}
                  onChange={(e) => setSortOrder(e.target.value)}
                  label="Sort by Deadline"
                  size="small"
                  sx={{ backgroundColor: '#ffffff' }}
                >
                  <MenuItem value="asc">Deadline Ascending</MenuItem>
                  <MenuItem value="desc">Deadline Descending</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Search Tasks"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{ width: '100%', backgroundColor: '#ffffff' }}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel>Task's column</InputLabel>
                <Select
                  value={columns}
                  onChange={(e) => setColumns(e.target.value)}
                  label="Task's column"
                  size="small"
                  sx={{ backgroundColor: '#ffffff' }}
                >
                  <MenuItem value={1}>1 Column</MenuItem>
                  <MenuItem value={2}>2 Columns</MenuItem>
                  <MenuItem value={3}>3 Columns</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
            <ProjectTasks
              projectId={projectId}
              newTask={newTask}
              searchQuery={searchQuery}
              priorityFilter={priorityFilter}
              sortOrder={sortOrder}
              columns={columns}
            />
          </Box>

          <TaskForm open={openModal} handleClose={() => setOpenModal(false)} projectId={projectId} onTaskAdded={handleTaskAdded} />
        </>
      ) : (
        <Typography variant="body1">No project details found.</Typography>
      )}
    </Box>
  );
};

export default ProjectDetails;
