import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, CircularProgress, Typography, Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { supabase } from '../../supabase/supabaseClient';
import ProjectForm from './ProjectForm'; // ProjectForm visszahelyezése

const priorities = ['All', 'Low', 'Medium', 'High'];

const ProjectList = ({ onSelectProject }) => {
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [showProjectForm, setShowProjectForm] = useState(false); // Modal állapot
  const [priorityFilter, setPriorityFilter] = useState('All');
  const [deadlineSortOrder, setDeadlineSortOrder] = useState('closest');

  useEffect(() => {
    const fetchProjectsAndTasks = async () => {
      const { data: projectsData, error: projectsError } = await supabase.from('projects').select('*');
      const { data: tasksData, error: tasksError } = await supabase.from('tasks').select('*');

      if (projectsError || tasksError) {
        console.error('Error fetching data:', projectsError || tasksError);
      } else {
        setProjects(projectsData);
        setTasks(tasksData);
        setLoading(false);
      }
    };

    fetchProjectsAndTasks();
  }, []);

  const handleProjectAdded = (newProject) => {
    setProjects((prevProjects) => [newProject, ...prevProjects]); // Új projekt hozzáadása
    setShowProjectForm(false); // Modal bezárása
  };

  const filteredProjects = projects
    .filter((project) => {
      const taskNames = tasks
        .filter((task) => task.project_id === project.id)
        .map((task) => task.name.toLowerCase());

      const matchesSearchQuery = project.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        project.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
        taskNames.some((taskName) => taskName.includes(searchQuery.toLowerCase()));

      const matchesPriorityFilter = priorityFilter === 'All' || project.priority === priorityFilter;

      return matchesSearchQuery && matchesPriorityFilter;
    })
    .sort((a, b) => {
      if (deadlineSortOrder === 'closest') {
        return new Date(a.deadline) - new Date(b.deadline);
      } else {
        return new Date(b.deadline) - new Date(a.deadline);
      }
    });

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h4" gutterBottom>
        Projects
      </Typography>

      <Grid container spacing={2} alignItems="center" mb={2}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowProjectForm(true)} // Modal megnyitása
            sx={{ width: '100%' }}
          >
            Add Project
          </Button>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Search Projects"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ width: '100%' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" mb={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Sort by Deadline</InputLabel>
            <Select
              value={deadlineSortOrder}
              onChange={(e) => setDeadlineSortOrder(e.target.value)}
              label="Sort by Deadline"
              size="small"
            >
              <MenuItem value="closest">Closest Deadline</MenuItem>
              <MenuItem value="farthest">Farthest Deadline</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Priority</InputLabel>
            <Select
              value={priorityFilter}
              onChange={(e) => setPriorityFilter(e.target.value)}
              label="Priority"
              size="small"
            >
              {priorities.map((priority) => (
                <MenuItem key={priority} value={priority}>
                  {priority}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <List>
          {filteredProjects.length === 0 ? (
            <Typography>No projects found</Typography>
          ) : (
            filteredProjects.map((project) => (
              <ListItem
                key={project.id}
                sx={{
                  backgroundColor: '#f7f7f7',
                  borderRadius: '8px',
                  marginBottom: '10px',
                  '&:hover': {
                    backgroundColor: '#e0e0e0',
                  },
                }}
                onClick={() => onSelectProject(project)}
              >
                <ListItemText
                  primary={project.name}
                  secondary={project.description.length > 40 ? `${project.description.substring(0, 40)}...` : project.description}
                />
                <Typography variant="body2" sx={{ marginRight: '16px', alignSelf: 'center' }}>
                  {project.deadline ? new Date(project.deadline).toLocaleDateString() : 'No deadline'}
                </Typography>
              </ListItem>
            ))
          )}
        </List>
      </Box>

      {/* ProjectForm modal megjelenítése */}
      {showProjectForm && (
        <ProjectForm
          open={showProjectForm}
          handleClose={() => setShowProjectForm(false)} // Modal bezárása
          onProjectAdded={handleProjectAdded} // Új projekt hozzáadása a listához
        />
      )}
    </Box>
  );
};

export default ProjectList;
