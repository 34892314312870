import React, { useState } from 'react';
import { Grid, Container, Typography, Box, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ProjectList from '../components/ProjectsPageComponents/ProjectList';
import ProjectDetails from '../components/ProjectsPageComponents/ProjectDetails';
import ProjectForm from '../components/ProjectsPageComponents/ProjectForm';
import logo from '../assets/logos/LoveMyTask_logo.png'; 

const ProjectsPage = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleAddProject = () => {
    setOpenModal(true);
  };

  const handleProjectAdded = (newProject) => {
    setProjects((prevProjects) => [...prevProjects, newProject]);
    setOpenModal(false);
  };

  const handleSelectProject = (project) => {
    setSelectedProject(project);
    if (isMobile) {
      navigate(`/projects/${project.id}`);
    }
  };

  return (
    <Container
      sx={{
        height: 'calc(100vh - 64px)',
        padding: '16px 0',
        boxSizing: 'border-box',
      }}
    >
      <Grid container spacing={3} sx={{ height: '100%' }}>
        <Grid item xs={12} md={4} sx={{ height: '100%' }}>
          <Box sx={{ height: '100%', overflowY: 'auto' }}>
            <ProjectList onSelectProject={handleSelectProject} projects={projects} />
          </Box>
        </Grid>
        <Grid item xs={12} md={8} sx={{ height: '100%' }}>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {!selectedProject && !isMobile && (
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <img src={logo} alt="Logo" style={{ width: '200px', marginBottom: '20px' }} />
                <Typography variant="h5">Pick your next project</Typography>
              </Box>
            )}
            {selectedProject && !isMobile && (
              <ProjectDetails projectId={selectedProject.id} />
            )}
          </Box>
        </Grid>
      </Grid>

      <ProjectForm
        open={openModal}
        handleClose={() => setOpenModal(false)}
        onProjectAdded={handleProjectAdded}
      />
    </Container>
  );
};

export default ProjectsPage;
