import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography, Box, Grid } from '@mui/material';
import TaskCard from './TaskCard'; 
import { supabase } from '../../supabase/supabaseClient';

const ProjectTasks = ({ projectId, newTask, searchQuery, priorityFilter, sortOrder, columns }) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedTaskId, setExpandedTaskId] = useState(null);

  useEffect(() => {
    const fetchTasks = async () => {
      const { data, error } = await supabase
        .from('tasks')
        .select('*')
        .eq('project_id', projectId);

      if (error) {
        console.error('Error fetching tasks:', error);
      } else {
        setTasks(data);
        setLoading(false);
      }
    };

    fetchTasks();
  }, [projectId]);

  useEffect(() => {
    if (newTask) {
      setTasks((prevTasks) => [...prevTasks, newTask]);
    }
  }, [newTask]);

  const handleToggleExpandTask = (taskId) => {
    setExpandedTaskId((prev) => (prev === taskId ? null : taskId)); // Kibővítés állapotának kezelése
  };

  const handleDeleteTask = (taskId) => {
    setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
  };

  const handleUpdateTask = async (taskId, updatedFields) => {
    const { error } = await supabase
      .from('tasks')
      .update(updatedFields)
      .eq('id', taskId);

    if (error) {
      console.error('Error updating task:', error);
      alert('Failed to update task.');
    } else {
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId ? { ...task, ...updatedFields } : task
        )
      );
      alert('Task successfully updated.');
    }
  };

  const filteredTasks = tasks
    .filter((task) => {
      return (
        (priorityFilter === 'All' || task.priority === priorityFilter) &&
        (task.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          task.description.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    })
    .sort((a, b) => {
      if (sortOrder === 'asc') {
        return new Date(a.deadline) - new Date(b.deadline);
      } else {
        return new Date(b.deadline) - new Date(a.deadline);
      }
    });

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ marginTop: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {filteredTasks.length > 0 ? (
          <Grid container spacing={2}>
            {filteredTasks.map((task) => (
              <Grid item xs={12 / columns} key={task.id}>
                <TaskCard
                  task={task}
                  expanded={expandedTaskId === task.id}
                  onToggleExpand={handleToggleExpandTask}
                  onDelete={handleDeleteTask}
                  onUpdate={handleUpdateTask}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body1">No tasks available for this project.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default ProjectTasks;
