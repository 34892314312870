import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/LoginPage';
import Home from './pages/Home';
import ProjectsPage from './pages/ProjectsPage';
import ProjectDetailsPage from './pages/onlyMobile/ProjectDetailsPage';
import { supabase } from './supabase/supabaseClient';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Navbar from './components/MenuBar/Navbar';
import { Box, GlobalStyles } from '@mui/material';

// Funkció az eszköz méretének ellenőrzésére (mobil vagy desktop)
const isMobile = () => window.innerWidth <= 768;

const ProtectedRoute = ({ children }) => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSession = async () => {
      const { data } = await supabase.auth.getSession();
      setSession(data.session);
      setLoading(false);
    };

    getSession();
  }, []);

  if (loading) return <p>Loading...</p>;

  return session ? (
    <>
      <Navbar />
      <Box sx={{ height: 'calc(100vh - 64px)', marginTop: '64px' }}>
        {children}
      </Box>
    </>
  ) : (
    <Navigate to="/" />
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          body: {
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
            fontFamily: 'Urbanist, sans-serif',
          },
        }}
      />
      <Router>
        <Routes>
          {/* Bejelentkezési oldal */}
          <Route path="/" element={<Login />} />

          {/* Védett Home oldal */}
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />

          {/* Védett Projects oldal */}
          <Route
            path="/projects"
            element={
              <ProtectedRoute>
                <ProjectsPage />
              </ProtectedRoute>
            }
          />

          {/* Mobilon ProjectDetails oldal */}
          <Route
            path="/projects/:projectId"
            element={
              isMobile() ? (
                <ProtectedRoute>
                  <ProjectDetailsPage />
                </ProtectedRoute>
              ) : (
                <Navigate to="/projects" />
              )
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
