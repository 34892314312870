import React, { useState, useEffect } from 'react';
import { Card, CardContent, Box, Typography, IconButton, TextField, Select, MenuItem, Checkbox, LinearProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { supabase } from '../../supabase/supabaseClient';

const TaskCard = ({ task, expanded, onToggleExpand, onDelete, onUpdate }) => {
  const [editedDescription, setEditedDescription] = useState(task.description);
  const [editedDeadline, setEditedDeadline] = useState(task.deadline);
  const [editedPriority, setEditedPriority] = useState(task.priority);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isEditingDeadline, setIsEditingDeadline] = useState(false);
  const [isEditingPriority, setIsEditingPriority] = useState(false);
  const [subtasks, setSubtasks] = useState([]);
  const [newSubtask, setNewSubtask] = useState('');

  // Fetch subtasks when component mounts
  useEffect(() => {
    const fetchSubtasks = async () => {
      const { data, error } = await supabase
        .from('subtasks')
        .select('*')
        .eq('task_id', task.id);

      if (error) {
        console.error('Error fetching subtasks:', error);
      } else {
        setSubtasks(data);
      }
    };

    fetchSubtasks();
  }, [task.id]);

  // Toggle subtask completion
  const handleSubtaskToggle = async (subtaskId, completed) => {
    const updatedSubtasks = subtasks.map((subtask) =>
      subtask.id === subtaskId ? { ...subtask, completed: !completed } : subtask
    );
    setSubtasks(updatedSubtasks);

    // Update in the database
    await supabase
      .from('subtasks')
      .update({ completed: !completed })
      .eq('id', subtaskId);
  };

  // Add new subtask
  const handleNewSubtask = async (e) => {
    if (e.key === 'Enter' && newSubtask.trim() !== '') {
      const { data, error } = await supabase
        .from('subtasks')
        .insert([{ name: newSubtask, completed: false, task_id: task.id }])
        .select();

      if (!error && data) {
        setSubtasks([...subtasks, ...data]);
        setNewSubtask('');
      }
    }
  };

  // Calculate progress
  const completedCount = subtasks.filter((subtask) => subtask.completed).length;
  const progress = subtasks.length > 0 ? (completedCount / subtasks.length) * 100 : 0;

  // Task Description editing and saving on Enter
  const handleDescriptionKeyPress = async (e) => {
    if (e.key === 'Enter') {
      setIsEditingDescription(false);
      await supabase
        .from('tasks')
        .update({ description: editedDescription })
        .eq('id', task.id);
    }
  };

  // Task Deadline editing and saving on Enter
  const handleDeadlineKeyPress = async (e) => {
    if (e.key === 'Enter') {
      setIsEditingDeadline(false);
      await supabase
        .from('tasks')
        .update({ deadline: editedDeadline })
        .eq('id', task.id);
    }
  };

  // Task Priority editing and saving on change
  const handlePriorityChange = async (e) => {
    setEditedPriority(e.target.value);
    await supabase
      .from('tasks')
      .update({ priority: e.target.value })
      .eq('id', task.id);
    setIsEditingPriority(false);
  };

  // Task deletion based on user_id
  const handleDeleteTask = async () => {
    const confirmed = window.confirm("Are you sure you want to delete this task?");
    if (confirmed) {
      const { data: { session } } = await supabase.auth.getSession();

      if (!session) {
        return;
      }

      const userId = session.user.id;

      // Check if the task belongs to the user
      const { data: taskData, error: fetchError } = await supabase
        .from('tasks')
        .select('user_id')
        .eq('id', task.id)
        .single();

      if (fetchError || !taskData) {
        return;
      }

      if (taskData.user_id !== userId) {
        return;
      }

      const { error } = await supabase
        .from('tasks')
        .delete()
        .eq('id', task.id);

      if (!error) {
        onDelete(task.id); // Call onDelete to update the state
      }
    }
  };

  return (
    <Card sx={{ backgroundColor: '#ffffff', boxShadow: 1, borderRadius: 2 }}>
      <CardContent>
        {/* Progress Bar */}
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ height: 8, borderRadius: 4, marginBottom: 2, backgroundColor: '#e0e0e0' }}
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Task Name (Not editable) */}
          <Box sx={{ width: '80%', cursor: 'pointer' }} onClick={() => onToggleExpand(task.id)}>
            <Typography variant="h6" component="div" noWrap>
              {task.name}
            </Typography>
          </Box>
          <IconButton
            onClick={handleDeleteTask}
            aria-label="delete"
            color="error"
            sx={{ fontSize: '1rem' }}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Box>

        {expanded && (
          <Box sx={{ marginTop: 1 }}>
            {/* Task Description editing */}
            <Box onClick={() => setIsEditingDescription(true)} sx={{ cursor: 'pointer' }}>
              {isEditingDescription ? (
                <TextField
                  fullWidth
                  value={editedDescription}
                  onChange={(e) => setEditedDescription(e.target.value)}
                  onKeyPress={handleDescriptionKeyPress} // Save on Enter
                  variant="standard"
                  autoFocus
                />
              ) : (
                <Typography variant="body2" color="text.secondary">
                  {task.description}
                </Typography>
              )}
            </Box>

            {/* Task Deadline editing */}
            <Box onClick={() => setIsEditingDeadline(true)} sx={{ cursor: 'pointer', marginTop: 1 }}>
              {isEditingDeadline ? (
                <TextField
                  fullWidth
                  type="date"
                  value={editedDeadline ? new Date(editedDeadline).toISOString().split('T')[0] : ''}
                  onChange={(e) => setEditedDeadline(e.target.value)}
                  onKeyPress={handleDeadlineKeyPress} // Save on Enter
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoFocus
                />
              ) : (
                <Typography variant="body2" color="text.secondary">
                  Deadline: {task.deadline ? new Date(task.deadline).toLocaleDateString() : 'No deadline'}
                </Typography>
              )}
            </Box>

            {/* Task Priority editing */}
            <Box sx={{ cursor: 'pointer', marginTop: 1 }}>
              {isEditingPriority ? (
                <Select
                  fullWidth
                  value={editedPriority}
                  onChange={handlePriorityChange} // Save on change
                  autoFocus
                >
                  <MenuItem value="Low">Low</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="High">High</MenuItem>
                </Select>
              ) : (
                <Typography variant="body2" color="text.secondary" onClick={() => setIsEditingPriority(true)}>
                  Priority: {task.priority}
                </Typography>
              )}
            </Box>

            {/* Subtasks */}
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="body2">Subtasks:</Typography>
              {subtasks.map((subtask) => (
                <Box key={subtask.id} sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                  <Checkbox
                    checked={subtask.completed}
                    onChange={() => handleSubtaskToggle(subtask.id, subtask.completed)}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      textDecoration: subtask.completed ? 'line-through' : 'none',
                    }}
                  >
                    {subtask.name}
                  </Typography>
                </Box>
              ))}
              <TextField
                placeholder="Add new subtask"
                fullWidth
                value={newSubtask}
                onChange={(e) => setNewSubtask(e.target.value)}
                onKeyPress={handleNewSubtask}
                variant="standard"
                sx={{ marginTop: 1 }}
              />
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default TaskCard;
