import React, { useState } from 'react';
import { Modal, TextField, Button, Box, MenuItem } from '@mui/material';
import { supabase } from '../../supabase/supabaseClient';

const priorities = ['Low', 'Medium', 'High']; // Példa prioritások

const TaskForm = ({ open, handleClose, projectId, onTaskAdded }) => {
  const [taskName, setTaskName] = useState('');
  const [description, setDescription] = useState('');
  const [deadline, setDeadline] = useState('');
  const [priority, setPriority] = useState('Medium'); // Alapértelmezett érték

  const resetForm = () => {
    setTaskName('');
    setDescription('');
    setDeadline('');
    setPriority('Medium');
  };

  const handleAddTask = async () => {
    // Megszerzi a bejelentkezett felhasználó azonosítóját
    const { data: { session } } = await supabase.auth.getSession();

    if (!session) {
      alert('User is not authenticated');
      return;
    }

    const userId = session.user.id; // A felhasználó azonosítója

    // Task létrehozása a user_id hozzáadásával
    const { data, error } = await supabase
      .from('tasks')
      .insert([
        {
          name: taskName,
          description: description,
          deadline: deadline,
          priority: priority,
          project_id: projectId,
          user_id: userId // Hozzáadjuk a felhasználó azonosítóját
        }
      ])
      .select();  // Ezzel kérjük vissza a beszúrt adatokat
  
    if (error) {
      console.error('Error adding task:', error);
    } else {
      if (data && data.length > 0) {
        onTaskAdded(data[0]);
        resetForm(); // Mezők alaphelyzetbe állítása
        handleClose(); // Modal bezárása
      } else {
        console.error('No task returned from the insert operation.');
      }
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          // Mobil margin hozzáadása
          '@media (max-width:600px)': {
            width: '90%', // Szélesség kisebb kijelzőkön
            padding: '16px', // Padding kisebb kijelzőkre
          },
        }}
      >
        <h2>Add New Task</h2>
        <TextField
          label="Task Name"
          fullWidth
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Description"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Deadline"
          type="date"
          fullWidth
          value={deadline}
          onChange={(e) => setDeadline(e.target.value)}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Priority"
          select
          fullWidth
          value={priority}
          onChange={(e) => setPriority(e.target.value)}
          margin="normal"
        >
          {priorities.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <Button variant="contained" color="primary" onClick={handleAddTask} style={{ marginTop: '1rem' }}>
          Add Task
        </Button>
      </Box>
    </Modal>
  );
};

export default TaskForm;
